import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://72365571a079e6c0ad0ff046b853c3ee@o4508235363450880.ingest.de.sentry.io/4508235368693840",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});